import './index.scss';
import 'moment/locale/zh-cn';
import 'moment/dist/locale/zh-cn';

import { ConfigProvider } from 'antd';
import zhCN from 'antd/es/locale/zh_CN';
import moment from 'moment';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import NiceModal from '@ebay/nice-modal-react';

import reportWebVitals from './reportWebVitals';
import Routes from './routes';

moment.locale('zh-cn')

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <ConfigProvider locale={zhCN}>
    <BrowserRouter>
      <NiceModal.Provider>
        <Routes />
      </NiceModal.Provider>
    </BrowserRouter>
  </ConfigProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
